// 最外层盒子样式
%out-box {
  // width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以100；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

@mixin text-overflow($width: 100%) {
  width: $width;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

// 活动列表
.activity-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .activity-li {
    width: (380rem / 100);
    height: (390rem / 100);
    margin-right: (30rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: (20rem / 100);
    position: relative;
    margin-bottom: (30rem / 100);

    &-banner {
      width: 100%;
      height: (200rem / 100);
      overflow: hidden;
      margin-bottom: (14rem / 100);
    }

    &-bottom {
      width: 100%;
      padding: (6rem / 100) (10rem / 100) 0;

      &-title {
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }

      &-des {
        width: 100%;
        // height: (16rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;

        .activity-li-bottom-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          // float: left;
          margin-right: (10rem / 100);
        }

        .activity-li-bottom-icon-title {
          // float: left;
          @include text-overflow(
            $width: (
              340rem / 100,
            )
          );
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          line-height: (16rem / 100);
        }
      }
    }
  }

  .activity-li-selected {
    width: 100%;
    height: (37rem / 100);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }

  .activity-li:nth-of-type(3n) {
    margin-right: 0;
  }

  .activity-li:hover {
    .activity-li-selected {
      display: block;
    }
  }
}

// 直播列表
.live-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (368rem / 100);
    box-sizing: border-box;
    background: #F3EDE0 ;
    margin-right: (30rem / 100);
    margin-bottom: (30rem / 100);
    border: 4px solid #f3ede0;
    cursor: pointer;
    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
      box-sizing: border-box;
      //padding: (20rem / 100);
      position: relative;
      //margin-bottom: (30rem / 100);

        .listState{
        position: absolute;
        left: 0rem;
        top: 0rem;
        display: flex;
        align-items: center;
        padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        color: white;
        }
        .current1{
          .live-ul-li-banner-icon{
            width: (17.65rem / 100);
            height: (15rem / 100);
            // float: left;
            margin-right: (10rem / 100);
        
          }
          background: #761F1E;
        }
        .current2{
          background: #CD9E56;
        }
        .current3{
          background: #000000;
        }
        .listStateFont{
          font-size: 0.15rem;
          display: flex;
        align-items: center;
        //width: (98rem / 100);
        height: (34rem / 100);
        }

        //.numberState{
        //position: absolute;
        //right: 0rem;
        //top: 0rem;
        //display: flex;
        //align-items: center;
        //height:(15rem / 100);
        //padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        //color: white;
        //.live-ul-li-banner-number-icon{
        //width: (17.65rem / 100);
        //height: (15rem / 100);
        // float: left;
        //margin-right: (10rem / 100);
        //}
        //.live-ul-li-banner-title{
        //  //height: (15rem / 100);
        //}
        //background: #C7C8C8;
        //}
        .numberAfterState1{
          position: absolute;
          left: 0rem;
          bottom: 0rem;
          .live-ul-li-banner-numberAfter-background{
            position: relative;
            margin-bottom: 0rem;
            margin-left: 0rem;
            //height: 25px;
            width:100%;
            overflow: hidden;
            opacity: 100;
          }
        //display: flex;
        //align-items: center;
        //width: 272px;
        //padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        }
        .numberAfterState{
          position: absolute;
        left: 0rem;
        bottom: 0rem;
        display: flex;
        align-items: center;
        padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        color: white;
        .live-ul-li-banner-numberAfter-icon{
          width: (22.86rem / 100);
        height: (22.86rem / 100);
        // float: left;
        margin-right: (10rem / 100);
        }
        
        opacity: 100;
        }
      
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }

      &-des {
        width: 100%;
        // height: (16rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;

        &-left{
          display: flex;
          align-items: center;
          .activity-li-bottom-icon {
            width: (24rem / 100);
            height: (24rem / 100);
            // float: left;
            //margin-right: (10rem / 100);
          }
  
          .activity-li-bottom-icon-title {
            // float: left;
            @include text-overflow(
              $width: (
                340rem / 100,
              )
            );
            margin-left: 10px;
            font-size: (16rem / 100);
            font-family: PingFangSC-Regular, PingFang SC;
            color: #273143;
            line-height: (16rem / 100);
          }
        }
        &-right
        {
          //display: flex;
          //align-items: center;
          //position: absolute;
          //font-size: 0.15rem;
          flex: 1;
          text-align: right;  
          //height:(22rem/100);
          
          .live-ul-li-bottom-des-right-title{
            
            line-height: (16rem / 100);
          }
        }
        
      }

      &-status {
        width: (100rem / 100);
        height: (26rem / 100);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-align: center;
        line-height: (26rem / 100);
        margin-bottom: (10rem / 100);
      }

      .live-status-bg1 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_starting.png");
        color: #ffffff;
      }

      .live-status-bg2 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_not_start.png");
        color: #761f1e;
      }

      &-sponsor {
        margin-bottom: (12rem / 100);
      }

      &-sponsor,
      &-summary {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (16rem / 100);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}

// 艺术培训
.culture-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .culture-li {
    width: (380rem / 100);
    height: (390rem / 100);
    margin-right: (30rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: (20rem / 100);
    position: relative;
    margin-bottom: (30rem / 100);

    &-banner {
      width: 100%;
      height: (200rem / 100);
      overflow: hidden;
      margin-bottom: (14rem / 100);
    }

    &-bottom {
      width: 100%;
      padding: (6rem / 100) (10rem / 100) 0;

      &-title {
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: (11rem / 100);
      }

      &-des {
        width: 100%;
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;

        .culture-li-bottom-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (10rem / 100);
        }

        .culture-li-bottom-icon-title {
          @include text-overflow(
            $width: (
              340rem / 100,
            )
          );
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          line-height: (16rem / 100);
        }
      }
    }
  }

  .culture-li-selected {
    width: 100%;
    height: (37rem / 100);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }

  .culture-li:nth-of-type(3n) {
    margin-right: 0;
  }

  .culture-li:hover {
    .culture-li-selected {
      display: block;
    }
  }
}

// 非遗文化
.intangible-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (330rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;
    margin-bottom: (30rem / 100);

    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}
// 非遗文化
.teachvideo-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (330rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;
    margin-bottom: (30rem / 100);

    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}
// 特色资源库
.resources-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (330rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;
    margin-bottom: (30rem / 100);

    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}

// 特色资源库-电子书
.resources-t-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (176rem / 100);
    box-sizing: border-box;
    margin-right: (80rem / 100);
    margin-bottom: (40rem / 100);

    &-banner {
      width: 100%;
      border: 4px solid transparent;
      height: (260rem / 100);
      margin: 0 auto (8rem / 100);
    }

    &-bottom {
      width: (176rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (48rem / 100);
        font-size: (16rem / 100);
        font-family: PingFangSC-Regular, PingFang SC;
        color: #273143;
        line-height: (24rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }
    }
  }

  &-li:nth-of-type(5n) {
    margin-right: 0;
  }

  &-li:hover {
    .resources-t-ul-li-banner {
      border: 4px solid #cd9e56;
    }

    .resources-t-ul-li-bottom {
      .resources-ul-li-bottom-title {
        color: #cd9e56;
      }
    }
  }
}

// 志愿服务
.volunteer-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (330rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;
    margin-bottom: (30rem / 100);

    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
      position: relative;

      &-total {
        width: (68rem / 100);
        height: (30rem / 100);
        background: #cd9e56;
        border-radius: 0 (20rem / 100) 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        text-indent: (13rem / 100);
        line-height: (30rem / 100);
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      }
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}

// 场馆预定
.maplist-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (283rem / 100);
    margin-right: (30rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: (20rem / 100);
    position: relative;
    margin-bottom: (30rem / 100);

    &-banner {
      width: 100%;
      height: (200rem / 100);
      overflow: hidden;
      margin-bottom: (14rem / 100);
      position: relative;

      &-total {
        width: (68rem / 100);
        height: (30rem / 100);
        background: #cd9e56;
        border-radius: 0 (20rem / 100) 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        line-height: (30rem / 100);
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      }
    }

    &-bottom {
      width: 100%;
      padding: 0 (10rem / 100) 0;

      &-title {
        width: (340rem / 100);
        height: (28rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        margin-bottom: (11rem / 100);
      }
    }
  }

  .maplist-li-selected {
    width: 100%;
    height: (37rem / 100);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    .maplist-li-selected {
      display: block;
    }
  }
}

// 首页直播列表
.live-ul1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (424rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;

    &-banner {
      width: (340rem / 100);
      height: (200rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (5rem / 100);
      }

      &-status {
        width: (100rem / 100);
        height: (26rem / 100);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-align: center;
        line-height: (26rem / 100);
        margin-bottom: (10rem / 100);
      }

      .live-status-bg1 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_starting.png");
        color: #ffffff;
      }

      .live-status-bg2 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_not_start.png");
        color: #761f1e;
      }

      &-sponsor {
        margin-bottom: (8rem / 100);
      }

      &-sponsor {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (16rem / 100);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-summary {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (24rem / 100);
        width: 3.4rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}


.listState{
  position: absolute;
  left: 0rem;
  top: 0rem;
  padding: 0.04rem 0.08rem 0.04rem 0.08rem;
  color: white;
}
.current1{
  background: rgb(170,170,170);
}
.current2{
  background: rgb(245,154,35);
}
.current4{
  background: rgb(112,182,3);
}
.listStateFont{
  font-size: 0.15rem;
}
.resources-ul-li{
  overflow: hidden;
}